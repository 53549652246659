import { useState } from "react";
import { GetRandomPicturesURL } from "../../functions.js";


import CategoryChoiceList from "./Components/CategoryChoiceList.js";
import PictureList from "./Components/PictureList.js";
import "./photos.css";

export default function PhotosPage() {
  document.title = "Portfolio | Photos";
  document.body.style.color = "black";
  // if empty just get photos
  const [currentCategoryID, setCurrentCategoryId] = useState(
    GetRandomPicturesURL()
  );
  
  return (
    <main className="photos">
      <div className="container-photos">
        <hr/>
        <CategoryChoiceList setCurrentCategoryId={setCurrentCategoryId} />
        <PictureList currentCategoryID={currentCategoryID} />
      </div>
    </main>
  );
}
