import uuid from "react-uuid";
import { GetPhotos } from "../../../functions";
import { useEffect, useState } from "react";
import { ImageView1x1, ImageView3x3 } from "./ImageView";
import LoadScreen from "../../../Components/LoadScreen";

function PictureList({ currentCategoryID }) {
  const [linkList, setList] = useState([]);

  const [currentWidth, setCurrentHeight] = useState(window.innerWidth);
  //let currentWidth = window.innerWidth;
  window.addEventListener("resize", (event) => {
    //currentWidth = window.innerWidth;
    setCurrentHeight(window.innerWidth);
  });

  useEffect(() => {
    GetPhotos(currentCategoryID, setList);
  }, [currentCategoryID]);

  return (
    <>
      {currentWidth >= 1300 ? (
        <ImageView3x3 key={uuid()} linkList={linkList} />
      ) : (
        <ImageView1x1 key={uuid()} linkList={linkList} />
      )}
    </>
  );
}

export default PictureList;
