import axios from "axios";


const baseURL = process.env.REACT_APP_API_LINK;

export async function GetCategoryList() {
   return axios
    .get(baseURL + "/collections")
    .then((response) => {
      
      return response.data;
    })
    .catch((err) => {
      alert(err);
      return err;
    });
}
export function SelectCurrentCategory(elementID, setCurrentCategoryId) {
  const url2 = baseURL + "/pictures/" + elementID;
  setCurrentCategoryId(url2);
}
export function GetRandomPicturesURL() {
  const url = baseURL + "/pictures";
  return url;
}

export async function GetPhotos(currentCategoryID, setList) {
  return axios
    .get(currentCategoryID)
    .then((response) => {
      console.log("Success");

      const list = response.data.map((element) => {
        return element;
      });
      setList(list);
    })
    .catch((err) => {
      console.log(err);
    });
}
